
import { useEffect, useRef } from 'react';
import styles from "./Info03.module.scss";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import "swiper/css";
import "swiper/css/effect-fade";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Info03 = (): JSX.Element => {
    const swiperRef = useRef<any>(null)

    useEffect(() => {
        ScrollTrigger.matchMedia({
            "(min-width: 0px)": function () {
                info03_ani();
            },
        });
    }, [])

    const info03_ani = () => {
        const tl = gsap.timeline()

        // 애니메이션 타임라인 정의
        tl.from(`.${ styles.titleContainer }`, {
            xPercent: -50,
            duration: 0.5,
            ease: "back(1.3)",
            autoAlpha: 0,
        })
        tl.from(`.${ styles.titleContainer } > div > .${ styles.sub }`, {
            y: 30,
            duration: 0.6,
            ease: "sine.inOut",
            autoAlpha: 0,
        }, "-=0.2")
        tl.from(`.${ styles.titleContainer } > div > .${ styles.sub2 }`, {
            y: 30,
            duration: 0.6,
            ease: "sine.inOut",
            autoAlpha: 0,
        }, "-=0.4")
        ScrollTrigger.create({
            trigger: `.${ styles.titleContainer }`,
            animation: tl,
            start: "top center",
            end: "bottom center",
          //  markers:true,
        })
    }

    
    const goPrevSlide = () => {
        swiperRef.current.swiper.slidePrev()
    }

    const goNextSlide = () => {
        swiperRef.current.swiper.slideNext()
    }

    const handleSlideChange = () => {
        const slides = swiperRef.current.swiper.slides;
        slides.forEach((slide: { querySelector: (arg0: string) => any; }) => {
            const textContainer = slide.querySelector(`.${styles.textContainer}`);
            if (textContainer) {
                textContainer.classList.remove(styles.active);
            }
        })
        const activeSlide = slides[swiperRef.current.swiper.activeIndex];
        const activeTextContainer = activeSlide.querySelector(`.${styles.textContainer}`);
        if (activeTextContainer) {
            activeTextContainer.classList.add(styles.active);
        }
    }

    return (
        <div className={ styles.container }>
            <div className={ styles.contents }>
                <div className={ styles.titleContainer }>
                    <div className={ styles.title }>
                        <span>
                            왜,
                            <div className={ styles.rowContainer }>
                                <div className={ styles.slideContainer }>
                                    <div className={ styles.item }>
                                        <span>더스윙제트</span>
                                        <span>더스윙제트</span>
                                    </div>
                                </div>
                                <span>인가?</span>
                            </div>
                        </span>
                    </div>

                    <div>
                        <span className={ styles.sub }>WHY CHOOSE US</span>
                    </div>

                    <div>
                        <span className={ styles.sub2 }>
                            스크린골프 창업은 어떤 브랜드를 사업 파트너로 <br className={ styles.br_m } />결정하는가에 따라 성공 여부가 달라집니다. <br />
                            가장 최신의 시스템인지, 또 얼마나 합리적인 <br className={ styles.br_m } />투자비용이 드는지, <br className={ styles.br_w } />
                            추가 수익을 창출할 있는지를 <br className={ styles.br_m } /> 살펴본다면 해답은 단 하나, 더스윙제트 입니다.
                        </span>
                    </div>
                </div>

                <div className={ styles.swiperContainer }>
                    <Swiper
                        ref={ swiperRef }
                        spaceBetween={ 30 }
                        slidesPerView={ 1 }
                        className={ styles.swiper }
                        modules={[Navigation, Autoplay]}
                        navigation={ true } 
                        loop={ true } 
                        speed={ 1500 } 
                        autoplay={{ delay: 5000, disableOnInteraction: false }}
                        lazyPreloadPrevNext={1}
                        onSlideChange={ handleSlideChange } // 슬라이드 변경 이벤트 핸들러 추가
                        onInit={ handleSlideChange } 
                    >   
                        { Array.from({ length: 13 }, (_, i) => i).map((index: number) => {
                            const num = (index + 1) < 10 ? '0' + (index + 1) : index + 1
                            
                            return (
                                <SwiperSlide className={ styles.swiperSlide } key={ index }>
                                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info03_slide_img${num}.jpg`} alt="info03" key={ index }/>
                                    
                                    { index < 4 &&
                                        <div className={ styles.textContainer }>
                                            <span className={ styles.text1 }>
                                                고객이 찾는 <span>최신 시스템</span>의 <br className={ styles.br_m } />스크린골프
                                            </span>
                                            <span className={ styles.text2 }>
                                                언리얼 엔진 5.3으로 시작되는 <br className={ styles.br_m } />압도적 리얼함 이야말로 새로운 필드의 기준
                                            </span>
                                    
                                            <span className={ styles.text3 }>
                                                높아진 고객의 눈높이를 맞추는 것은 쉽지 않습니다. <br />
                                                조금씩 좋아지는 것으로는 부족하기에 <br className={ styles.br_m } />압도적인 리얼함으로 새로운 필드를 선보입니다.
                                            </span>
                                        </div>
                                    }

                                    { index < 9 && index > 4 &&
                                        <div className={ styles.textContainer }>
                                            <span className={ styles.text1 }>
                                                <span>창업비</span> 부담없는 <br className={ styles.br_m } />스크린골프
                                            </span>
                                            <span className={ styles.text2 }>
                                                최신이자 최고의 Z-SYSTEM이지만 <br className={ styles.br_m } />특별한 기회로 만나는 새로운 필드의 기준
                                            </span>
                                    
                                            <span className={ styles.text3 }>
                                                1타석 4,600만원의 Z-SYSTEM을 <br className={ styles.br_m }  />1,500만원 할인된 3,100만원으로, <br className={ styles.br_m } />누구나 최고의 시스템을 <br className={ styles.br_w } />
                                                경험할 수 있어야 하기에 <br className={ styles.br_m }  />가장 합리적인 조건으로 새로운 필드의 <br className={ styles.br_m }  /> 세상을 함께
                                                시작할 수 있습니다.
                                            </span>

                                            <span className={ styles.text4 }>* 자세한 사항은 창업지원혜택을 확인하세요.</span>
                                        </div>
                                    }

                                    { index > 8 && 
                                        <div className={ styles.textContainer }>
                                            <span className={ styles.text1 }>
                                                <span>매출 한계</span>가 없는 스크린골프
                                            </span>
                                            <span className={ styles.text2 }>
                                                높은 수익을 자랑하는 <br className={ styles.br_m } />스크린골프 창업이지만 한계는 존재합니다. <br />
                                                그것을 넘어서는 것이 새로운 필드의 기준
                                            </span>
                                    
                                            <span className={ styles.text3 }>
                                                스크린골프는 더 이상 게임만을 즐기는 공간이 아닌 <br />
                                                즐거운 경험을 공유하고 <br className={ styles.br_m } />소통하는 공간으로 발전하고 있습니다. <br />
                                                진화하는 새로운 문화를 매출로 전환시킬 수 있는 <br className={ styles.br_m } />PAR-TEE FOOD (더스윙제트 F&B브랜드)의
                                                <br />
                                                플러스 혜택을 통해 기존 스크린골프 매출의 한계를 <br className={ styles.br_m } />넘어서는 새로운 필드를 경험해 보세요.
                                            </span>
                                        </div>
                                    }
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>

                    <div className={ styles.prev } onClick={ () => goPrevSlide() }>
                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info01_con_slide_prev_btn.svg`} alt="info03_prev" />
                    </div>
                    <div className={ styles.next } onClick={ () => goNextSlide() }>
                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info01_con_slide_next_btn.svg`} alt="info03_next" />
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default Info03
