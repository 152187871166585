import { combineReducers } from 'redux'
import page from './page'
import { DefaultRootState } from 'react-redux';

const rootReducer = combineReducers ({
    page
})

export type RootState = ReturnType<typeof rootReducer>

declare module 'react-redux' {
    interface DefaultRootState extends RootState {} //선언시 useSelector 에서 타입 자동 추론
}

export default rootReducer