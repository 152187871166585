import styles from "./Info09.module.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";

const Info09 = (): JSX.Element => {
    useEffect(() => {
        ScrollTrigger.matchMedia({
            "(min-width: 1025px)": function () {
                info09_ani();
            },
            "(max-width: 1024px)": function () {
                info09_ani_m()
            },
        });

    }, [])

    const info09_ani = () => {
        const tl = gsap.timeline()

        tl.from(`.${ styles.titleContainer } > .${ styles.dotContainer }`, {
            x: -50,
            duration: 0.5,
            ease: "power2.easeOut",
            stagger: 0.02,
            autoAlpha: 0,
        })
        tl.from(`.${ styles.titleContainer } > .${ styles.dotContainer } > span > span`,{
            autoAlpha: 0,
            scale: 3,
            duration: 0.15,
            stagger: 0.13,
            ease: "back",
        }, "-=0.4")
        tl.from(`.${ styles.titleContainer } > div:last-child`,{
            autoAlpha: 0,
            scale: 2,
            ease: "back",
        }, "-=0.8")
        tl.from(`.${ styles.progressContainer } > .${ styles.bar }`,{
            width: 0,
            duration: 0.6,
        }, "<")
        tl.from(`.${ styles.progressContainer } > .${ styles.contentsContainer } > .${ styles.text }`,{
            autoAlpha: 0,
            stagger: 0.07,
            ease: "linear",
        }, "-=0.4")
        tl.from(`.${ styles.textContainer }`,{
            autoAlpha: 0,
            y: 50,
        }, "-=0.8")
        
        ScrollTrigger.create({
			trigger: `.${ styles.contents }`,
			start: 'top center',
			end: 'bottom center',
			animation: tl,
		//	markers: true,
		})   
    }

    const info09_ani_m = () => {
        const tl = gsap.timeline();

        tl.from(`.${ styles.contents } > .${ styles.titleContainer } > .${ styles.dotContainer }`,{
            autoAlpha: 0,
            y: 50,
        })
        .from(`.${ styles.contents } > .${ styles.titleContainer } > .${ styles.dotContainer } > span > span`,{
            autoAlpha: 0,
            scale: 3,
            delay: 0.2,
            duration: 0.15,
            stagger: 0.15,
            ease: "back",
        },"<")
        .from(`.${ styles.contents } > .${ styles.titleContainer } > div:last-child`,{
            autoAlpha: 0,
            y: 50,
        },"-=0.6")
        .from(`.${ styles.contents } > .${ styles.progressContainer } > .${ styles.bar }`,{
            "height": 0,
            duration: 1.2,
        })
        .from(`.${ styles.contents } > .${ styles.progressContainer } > .${ styles.contentsContainer } > .${ styles.text }`,{
            autoAlpha: 0,
            stagger: 0.2,
        },"<")
        .from(`.${ styles.contents } > .${ styles.textContainer }`,{
            autoAlpha: 0,
            y: 50,
        }, "-=0.8")
        
        
        ScrollTrigger.create({
			trigger: `.${ styles.contents }`,
			start: 'top center',
			end: 'bottom center',
			animation: tl,
		//	markers: true,
		}) 
    }

    return (
        <div className={ styles.container }>
            <div className={ styles.contents }>
                <div className={ styles.titleContainer }>
                    <div className={ styles.dotContainer }>
                        <span>
                            더스윙제트 창업은 표준화 된 <br className={ styles.m } />
                            <span>7<div className={ styles.dot }></div></span>
                            <span>단<div className={ styles.dot }></div></span>
                            <span>계<div className={ styles.dot }></div></span>
                            <span>프<div className={ styles.dot }></div></span>
                            <span>로<div className={ styles.dot }></div></span>
                            <span>세<div className={ styles.dot }></div></span>
                            <span>스<div className={ styles.dot }></div></span>
                            를 통해
                        </span>
                    </div>
                    <div>
                        <span>
                            <span>빠르고 정확하게, <br className={ styles.m } />막힘 없이</span> 진행됩니다
                        </span>
                    </div>
                </div>

                <div className={ styles.progressContainer }>
                    <div className={ styles.bar }>
                        <img className={ styles.w } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info09_bar_w.svg`} alt="info09_bar_w" />
                        <img className={ styles.m } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info09_bar_m.svg`} alt="info09_bar_m" />
                    </div>

                    <div className={ styles.contentsContainer }>
                        <div className={ styles.text }>
                            <span>상담신청</span>
                            <div>
                                <div className={ styles.dot }></div>
                            </div>
                        </div>

                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info09_arrow.svg`} alt="info09_arrow" />

                        <div className={ styles.text }>
                            <span>방문상담</span>
                            <div>
                                <div className={ styles.dot }></div>
                            </div>
                        </div>
                        
                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info09_arrow.svg`} alt="info09_arrow" />

                        <div className={ styles.text }>
                            <span>현장실사 및 <br />상권 설정</span>
                            <div>
                                <div className={ styles.dot }></div>
                            </div>
                        </div>
                        
                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info09_arrow.svg`} alt="info09_arrow" />

                        <div className={ styles.text }>
                            <span>가맹계약</span>
                            <div>
                                <div className={ styles.dot }></div>
                            </div>
                        </div>
                        
                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info09_arrow.svg`} alt="info09_arrow" />
                        
                        <div className={ styles.text }>
                            <span>인테리어 및 <br className={ styles.m } />장비 설치, <br />점주 교육</span>
                            <div>
                                <div className={ styles.dot }></div>
                            </div>
                        </div>
                        
                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info09_arrow.svg`} alt="info09_arrow" />
                        
                        <div className={ styles.text }>
                            <span>매장오픈</span>
                            <div>
                                <div className={ styles.dot }></div>
                            </div>
                        </div>
                        
                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info09_arrow.svg`} alt="info09_arrow" />

                        <div className={ styles.text }>
                            <span> 본사 인력 <br/>3일 파견</span>
                            <div>
                                <div className={ styles.dot }></div>
                            </div>
                        </div>
                        
                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info09_arrow.svg`} alt="info09_arrow" />
                    </div>
                </div>

                <div className={ styles.textContainer }>
                    <span>표준화된 7단계 프로세스</span>
                </div>
            </div>
        </div>
    )
}

export default Info09