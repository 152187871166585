import styles from "./QuickBar.module.scss";
import { ChangeEvent, useEffect, useState } from "react";
import { useIsModalOpen, usePageActions } from "../hooks/usePage";
import { Inquiry } from "../types/apiType";
import { useApi } from "../hooks/useApi";

const QuickBar = (): JSX.Element => {
    const { saveIsModalOpen } = usePageActions()
    const { requestConsult } = useApi()
    const isModalOpen = useIsModalOpen()

    const [isConnected, setIsConnected] = useState<boolean>(false)
    const [isAgree, setIsAgree] = useState<boolean>(false)
    const [topForm, setTopForm] = useState<Inquiry>({
        name: '',
        phone: '',
        store: '',
        area: '',
        type: '',
        etc: '',
        email: '',
        path: '랜딩페이지(기본형)',
        code1: '',
        code2: '',
        code3: '',
        refer: ''
    })

    useEffect(() => {   
        setIsAgree(isModalOpen.isAgree)
    }, [isModalOpen])

    const call = () => {
        window.location.href = 'tel:18559753'
    }

    const request = async () => {
        if (isConnected) return

        if (topForm.name === '') {
            alert('이름을 입력해주세요.')
            return
        } else if (topForm.phone === '') {
            alert('휴대전화번호를 입력해주세요.')
            return
        } else if (topForm.phone.search(/\s/) > 0 || !/^(010)[0-9]{4}[0-9]{4}$/.test(topForm.phone)) {
            alert('유효하지 않은 휴대전화번호입니다.')
            return 
        } else if (!isAgree) {
            alert('개인정보수집 및 이용동의를 해주세요.')
            return 
        }

        const params = {
            name: topForm.name,
            phone: topForm.phone,
            store: '',
            area: '',
            type: '',
            etc: '',
            email: '',
            path: '랜딩페이지(기본형)',
            code1: '',
            code2: '',
            code3: '',
            refer: ''
        }

        setIsConnected(true)
        const payload = await requestConsult(params)
        setIsConnected(false)

        saveIsModalOpen({ type: 'complete', buttonExist: true, isAgree: isModalOpen.isAgree, isOpen: true })
    }   

    return (
        <div className={ styles.container }>
            <div className={ styles.quickContainer }>
                <div className={ styles.contentsContainer }>
                    <div className={ styles.titleContainer }>
                        <div className={ styles.rowContainer }>
                            <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/quick_tel_icon.svg`} alt="consult" />
                            <span>상담문의</span>
                        </div>
                        <span onClick={ call }>1855-0753</span>
                    </div>
                   
                    <div className={ styles.inputContainer }>
                        <input type="text" placeholder="이름(필수)" onChange={ (e: ChangeEvent<HTMLInputElement>) => setTopForm({ ...topForm, name: e.target.value })} />                        
                        <input type="text" placeholder="연락처(필수)" onChange={ (e: ChangeEvent<HTMLInputElement>) => setTopForm({ ...topForm, phone: e.target.value })}/>                        
                    </div>

                    <div className={ styles.agreeContainer }>
                        <div className={ styles.rowContainer } onClick={ () => setIsAgree(prev => !prev)}>
                            { isAgree ? 
                                <img className={ styles.icon } src={`${process.env.PUBLIC_URL}/assets/imgs/main/checkbox_icon_on.svg`} alt="checkbox" />
                                :
                                <img className={ styles.icon }  src={`${process.env.PUBLIC_URL}/assets/imgs/main/checkbox_icon_off.svg`} alt="checkbox" />
                            }
                            <span>개인정보수집 및 이용동의</span>
                        </div>
                        <span onClick={ () => saveIsModalOpen({ type: 'agree', buttonExist: true, isOpen: true, isAgree: isAgree }) }>자세히보기</span>
                    </div>

                    <button onClick={ request }>상담신청</button>
                </div>
            </div>
        </div>
    )
}

export default QuickBar
