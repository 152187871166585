import React, { useEffect, useState } from "react";
import styles from './Info12.module.scss';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Info12 = (): JSX.Element => {

    const info12_ani = () => {

        const tl = gsap.timeline();

        // 애니메이션 타임라인 정의
        tl.from(`.${styles.info12_con} > .${styles.contents_con} > .${styles.title_con}`, {
            y: -50,
            duration: 0.5,
            ease: "power2.easeOut",
            autoAlpha: 0,
        })
        .from(`.${styles.info12_con} > .${styles.contents_con} > .${styles.list_con} > .${styles.top_con}`, {
            x: 50,
            duration: 0.3,
            ease: "power2.easeOut",
            autoAlpha: 0,
        })
        .from(`.${styles.info12_con} > .${styles.contents_con} > .${styles.list_con} > .${styles.top_con} > .${styles.contents_con} > .${styles.text_con} .${styles.color_text} > .${styles.dot}`,{
            autoAlpha: 0,
            scale: 3,
            duration: 0.15,
            stagger: 0.15,
            ease: "back",
        },
            "-=0.4"
        )
        .from(`.${styles.info12_con} > .${styles.contents_con} > .${styles.list_con} > ul > li`,
        {
            x: 50,
            duration: 0.5,
            ease: "power2.easeOut",
            stagger: 0.3,
            autoAlpha: 0,
        },
            "-=0.4"
        );
        ScrollTrigger.create({
            trigger: `.${styles.info12_con} > .${styles.contents_con}`,
            start: "top center",
            end: "bottom center",
            animation: tl,
            // markers: true, // 디버깅용
        });
    }

    const m_info12_ani = () => {
        const tl = gsap.timeline();

        tl.from(`.${styles.info12_con} > .${styles.contents_con} > .${styles.title_con}`,{
            y: -50,
            duration: 0.5,
            ease: "power2.easeOut",
            autoAlpha: 0,
        }) 
        .from(`.${styles.info12_con} > .${styles.contents_con} >  .${styles.list_con} > .${styles.top_con}`,{
            x:50,
            duration: 0.5,
            ease: "power2.easeOut",
            autoAlpha: 0,
        })
        .from(`.${styles.info12_con} > .${styles.contents_con} > .${styles.list_con} > .${styles.top_con} > .${styles.contents_con} > .${styles.text_con} .${styles.color_text} > .${styles.dot}`,{
            autoAlpha:0,
            scale:3,
            duration:0.15,
            stagger:0.15,
            ease: "back",
        },"-=0.4")

        ScrollTrigger.create({
			trigger: `.${styles.info12_con} > .${styles.contents_con}`,
			start: 'top center',
			end: 'bottom center',
			animation: tl,
		//	markers: true,
		})    

        const listItems = document.querySelectorAll(`.${styles.info12_con} > .${styles.contents_con} > .${styles.list_con} > .${styles.ul} > .${styles.li}`);
        listItems.forEach((item, index) => {
            gsap.from(item, {
                x:-50,
                opacity: 0,
                scrollTrigger: {
                    trigger: item,
                    start: "top 60%",
                    end: "bottom 60%",
                //    markers: true,
                },
            });
        });
    }

    useEffect(() => {
        ScrollTrigger.matchMedia({
            // pc
            "(min-width: 1025px)": function () {
                info12_ani();
            },

            // mobile
            "(max-width: 1024px)": function () {
                m_info12_ani();
            },
        });
    }, []);

  return (
    <div className={styles.info12_con}>
        <div className={styles.contents_con}>
            <div className={styles.title_con}>
                <span>
                    창업비 파격 절감 <br/>                    
                    <span className={styles.color_text}>부담제로 더스윙제트</span>
                </span>
            </div>
            <div className={styles.list_con}>
                <div className={styles.top_con}>
                    <div className={styles.contents_con}>
                        <div className={styles.text_con}>
                            <span>
                                타석 별 <br className={styles.m_br}/>
                                <span className={styles.color_text}>1<div className={styles.dot}></div></span>
                                <span className={styles.color_text}>,</span>
                                <span className={styles.color_text}>5<div className={styles.dot}></div></span>
                                <span className={styles.color_text}>0<div className={styles.dot}></div></span>
                                <span className={styles.color_text}>0<div className={styles.dot}></div></span>
                                <span className={styles.color_text}>&nbsp;만<div className={styles.dot}></div></span>
                                <span className={styles.color_text}>원&nbsp;<div className={styles.dot}></div></span>
                                <br className={styles.m_br} />할인 혜택
                            </span>
                        </div>
                    </div>
                </div>

                <ul>
                    <li>
                        <div className={styles.list_div}>
                            <div className={styles.title_con}>
                                <div className={styles.text01_con}>
                                    <span>
                                        스페셜
                                    </span>
                                </div>

                                <div className={styles.text02_con}>
                                    <span>
                                        특별 파격 지원
                                    </span>
                                </div>
                            </div>

                            <div className={styles.list_con}>
                                <ul>
                                    <li>
                                        <div className={styles.list_div}>
                                            <span>
                                                가맹비 <span className={styles.line}><span className={styles.color_text}>1,000만원</span> 면제</span>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.list_div}>
                                            <span>
                                                교육비 <span className={styles.line}><span className={styles.color_text}>500만원</span> 면제</span>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.list_div}>
                                            <span>
                                                간판 지원비 최대 <span className={styles.line}><span className={styles.color_text}>500만원</span> 지원</span>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.list_div}>
                                            <span>
                                                로열티 최대 <span className={styles.line}><span className={styles.color_text}>300만원</span> 지원</span>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className={styles.list_div}>
                            <div className={styles.title_con}>
                                <div className={styles.text01_con}>
                                    <span>
                                        걱정뚝
                                    </span>
                                </div>

                                <div className={styles.text02_con}>
                                    <span>
                                        일사천리 매장 오픈
                                    </span>
                                </div>
                            </div>

                            <div className={styles.list_con}>
                                <ul>
                                    <li>
                                        <div className={styles.list_div}>
                                            <span>
                                                <span className={styles.line}><span className={styles.color_text}>36개월</span></span> 무이자 리스<br/>
                                                <span className={styles.gray_text}>(*일부 혜택 지원 불가)</span>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.list_div}>
                                            <span>
                                                초도물품 최대 <span className={styles.line}><span className={styles.color_text}>300만 포인트</span> 지원</span>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.list_div}>
                                            <span>
                                                A/S 출장비 <span className={styles.line}><span className={styles.color_text}>1년 무료</span></span>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.list_div}>
                                            <span>
                                                상권보호 <span className={styles.line}><span className={styles.color_text}>거리 확대</span></span><br/>
                                                <span className={styles.gray_text}>(경쟁 업체 대비 2배)</span>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className={styles.list_div}>
                            <div className={styles.title_con}>
                                <div className={styles.text01_con}>
                                    <span>
                                        오픈-런
                                    </span>
                                </div>

                                <div className={styles.text02_con}>
                                    <span>
                                        오픈 매출 폭발
                                    </span>
                                </div>
                            </div>

                            <div className={styles.list_con}>
                                <ul>
                                    <li>
                                        <div className={styles.list_div}>
                                            <span>
                                                <span className={styles.line}><span className={styles.color_text}>300만원 상당</span></span> 매장 광고 지원
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.list_div}>
                                            <span>
                                                <span className={styles.s_text}>김캐디 제휴</span><br/>
                                                <span className={styles.line}>사장님 솔루션 <span className={styles.color_text}>1년 지원</span></span>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.list_div}>
                                            <span>
                                                <span className={styles.s_text}>김캐디 제휴</span><br/>
                                                <span className={styles.line}>더스윙제트 <span className={styles.color_text}>독점 혜택</span></span>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.list_div}>
                                            <span>
                                                <span className={styles.s_text}>오픈 바이징</span><br/>
                                                <span className={styles.line}>본사인력 <span className={styles.color_text}>제트맨 파견</span></span>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
                
                <div className={styles.text_con}>
                    <ul>
                        <li>
                            <span>*</span>
                            <span>
                                탄탄대로의 모든 혜택은 5룸 이상 계약 시 적용되며 <br className={styles.m_br}/>4룸 이하의 경우에는 상담 시 문의 하세요.
                            </span>
                        </li>
                        <li>
                            <span>*</span>
                            <span>
                                36개월 무이자 할부 혜택 적용 시 가맹비 및 교육비 면제 <br className={styles.m_br}/>혜택은 적용되지 않습니다.
                            </span>
                        </li>
                    </ul>
                </div>
            </div>

            <div className={styles.m_con}>
                <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info12_con_img_m.png`} alt="컨텐츠 이미지" className={styles.fx}/>
            </div>

        </div>
    </div>
  );
}

export default Info12;