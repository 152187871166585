import axios from "axios"
import { Inquiry } from "../types/apiType"

interface ApiHooks {
    requestConsult: (params: Inquiry) => Promise<void>
}

export const useApi = (): ApiHooks => {
    
    const requestConsult = async (params: Inquiry) => {
        if (process.env.REACT_APP_BIZ_SERVER) {
            const response = await axios.post(process.env.REACT_APP_BIZ_SERVER, null, { params })
            .catch (e => {
                console.log(e)
            })
        }
    }

    return { requestConsult }
}