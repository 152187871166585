import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../redux"
import { useMemo } from "react"
import { bindActionCreators } from "redux"
import { Modal, saveIsModalOpen } from "../redux/page"


export const useIsModalOpen = (): Modal => {
    return useSelector((state: RootState) => state.page.isModalOpen)
}

export const usePageActions = () => {
    const dispatch = useDispatch()

    return useMemo(() => bindActionCreators({ saveIsModalOpen }, dispatch), [ dispatch ]) 
}