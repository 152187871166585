import styles from "./Info08.module.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";

const Info08 = (): JSX.Element => {
    useEffect(() => {
        ScrollTrigger.matchMedia({
            "(min-width: 0px)": function () {
                info08_ani()
            },
        });
    }, [])

    const info08_ani = () => {
        const tl = gsap.timeline()

        // 애니메이션 타임라인 정의
        tl.from(`.${ styles.titleContainer }`,{
            x: -150,
            duration: 0.6,
            ease: "back(1.3)",
            autoAlpha: 0,
        })
        .from(`.${ styles.titleContainer } > .${ styles.dotContainer }`,{
            autoAlpha: 0,
            y: 50,
        },"-=0.2")
        .from(`.${ styles.titleContainer } > .${ styles.dotContainer } > span > span`,{
            scale: 3,
            duration: 0.15,
            ease: "back",
            stagger: 0.15,
            autoAlpha: 0,
        }, "-=0.2")
        .from(`.${ styles.contentsContainer }`,{
            autoAlpha: 0,
            yPercent: 30,
            duration: 1.2,
        }, "<")
        
        ScrollTrigger.create({
			trigger: `.${ styles.titleContainer }`,
			start: 'top center',
			end: 'bottom center',
			animation: tl,
		//	markers: true,
		})   
    }

    return (
        <div className={ styles.container }>
           <div className={ styles.background }>
                <div className={ styles.ggd }>
                    <img className={ styles.marker } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_bg_marker.svg`} alt="info08_marker" />
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_bg_ggd.svg`} alt="info08_ggd" />
                    <span>Gyeongggi-do</span>
                </div>

                <div className={ styles.gwd }>
                    <img className={ styles.marker } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_bg_marker.svg`} alt="info08_marker" />
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_bg_gwd.svg`} alt="info08_gwd" />
                    <span>Gangwon-do</span>
                </div>

                <div className={ styles.ccd }>
                    <img className={ styles.marker } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_bg_marker.svg`} alt="info08_marker" />
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_bg_ccd.svg`} alt="info08_ccd" />
                    <span>Chung <br />cheong <br />-do</span>
                </div>

                <div className={ styles.dj }>
                    <img className={ styles.marker } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_bg_marker.svg`} alt="info08_marker" />
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_bg_dj.svg`} alt="info08_dj" />
                </div>

                <div className={ styles.gsd }>
                    <img className={ styles.marker } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_bg_marker.svg`} alt="info08_marker" />
                    <img  src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_bg_gsd.svg`} alt="info08_gsd" />
                    <span>Gyeongsang-do</span>
                </div>

                <div className={ styles.jld }>
                    <img className={ styles.marker } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_bg_marker.svg`} alt="info08_marker" />
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_bg_jld.svg`} alt="info08_jld" />
                    <span>Jeolla-do</span>
                </div>
           </div>

           <div className={ styles.contents }>
                <div className={ styles.titleContainer }>
                    <div>
                        <span>더스윙제트의</span>
                    </div>

                    <div className={ styles.colorText }>
                        <img className={ styles.w } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_title_w.svg`} alt="info08_title_w" />
                        <img className={ styles.m } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_title_m.svg`} alt="info08_title_m" />
                        <span>
                            <span>초고수익</span>제안
                        </span>
                    </div>

                    <div className={ styles.dotContainer }>
                        <span>
                            공간이 넓으면 
                            <span>&nbsp;복<div className={ styles.dot }></div></span>
                            <span>&nbsp;합<div className={ styles.dot }></div></span>
                            <span>&nbsp;매<div className={ styles.dot }></div></span>
                            <span>&nbsp;장<div className={ styles.dot }></div></span>
                            을 추천드립니다! <br className={ styles.m } />(최소 200평 이상 권장)
                        </span>
                    </div>
                </div>

                <div className={ styles.contentsContainer }>
                    <img className={ styles.w } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_flag_w.svg`} alt="info08_flag_w" />
                    <img className={ styles.m } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_flag_m.svg`} alt="info08_flag_m" />

                    <div className={ styles.textContainer }>
                        <div>
                            <span> 골프인구 천만 시대, 고객의 다양한 니즈를 <br className={ styles.m } />충족하기 위해서는 <br className={ styles.w } />
                            더 크고, 더 즐겁고, <br className={ styles.m } />더 새로운 형태의 스크린골프 공간이  필요합니다.</span>
                        </div>
                        <div>
                            <span>더스윙제트 창업 시, 프리미엄 골프 아카데미 <br className={ styles.m } />더스윙블랙과의 복합점 운영이 가능합니다. <br />
                            여기에 F&B 브랜드 PAR-TEE FOOD까지 <br className={ styles.m } />더해진다면 새로운 레저문화가 됩니다.</span>
                        </div>
                        <div>
                            <span>복합점 운영으로 더 큰 성장과 성공을  <br className={ styles.m } />계획하신다면, <br className={ styles.w } />
                            새로운 솔루션 <span>더스윙제트</span>가  <br className={ styles.m } />해결해 드리겠습니다.</span>
                        </div>
                    </div>

                    <div className={ styles.imgContainer }>
                        <div className={ styles.item }>
                            <div className={ styles.img }>
                                <img className={ styles.w } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_list_1_w.png`} alt="info08_list_1_w" />
                                <img className={ styles.m } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_list_1_m.svg`} alt="info08_list_1_m" />
                            </div>

                            <span>
                                높은 레슨율로 검증된 <br />
                                <span>프리미엄 골프 아카데이 브랜드</span>
                            </span>
                        </div>

                        <div className={ styles.x }>
                            <span>x</span>
                        </div>

                        <div className={ styles.item }>
                            <div className={ styles.img }>
                                <img className={ styles.w } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_list_2_w.png`} alt="info08_list_2_w" />
                                <img className={ styles.m } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_list_2_m.svg`} alt="info08_list_2_m" />
                            </div>

                            <span>
                                최고의 시스템을 갖춘 <br />
                                <span>차세대 스크린골프 브랜드</span>
                            </span>
                        </div>

                        <div className={ styles.x }>
                            <span>x</span>
                        </div>
                        
                        <div className={ styles.item }>
                            <div className={ styles.img }>
                                <img className={ styles.w } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_list_3_w.png`} alt="info08_list_3_w" />
                                <img className={ styles.m } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info08_list_3_m.svg`} alt="info08_list_3_m" />
                            </div>

                            <span>
                                프리미엄 그늘집 <br />
                                <span>F&B 브랜드</span>
                            </span>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    )
}

export default Info08