import { useNavigate } from "react-router";
import Footer from "../components/Footer";
import Header from "../components/Header";
import styles from "./Reception.module.scss";
import { useIsModalOpen } from "../hooks/usePage";
import AgreeModal from "../components/AgreeModal";
import { ChangeEvent, useEffect, useState } from "react";
import { Inquiry } from "../types/apiType";
import { useApi } from "../hooks/useApi";

const Reception = () => {
    const navigate = useNavigate()
    const isModalOpen = useIsModalOpen()
    const { requestConsult } = useApi()

    const selectable = ['찾아가는 창업설명회(상담 후 일정 조율)']
    const [isConnected, setIsConnected] = useState<boolean>(false)
    const [isAgree, setIsAgree] = useState<boolean>(false)
    const [form, setForm] = useState<Inquiry>({
        name: '',
        phone: '',
        store: '',
        area: '',
        type: '',
        etc: '',
        email: '',
        path: '창업설명회사전등록',
        code1: '',
        code2: '',
        code3: '',
        refer: ''
    })

    useEffect(() => {
        window.scroll({ top: 0, behavior: 'smooth' })
    }, [])

    const submit = async () => {
        // alert('시스템 점검중입니다. 전화로 문의해주세요.')
        if (isConnected) return

        if (!isAgree) {
            alert('개인정보수집 및 이용동의를 해주세요.')
            return
        } else if (form.name === '') {
            alert('이름을 입력해주세요.')
            return
        } else if (form.phone === '') {
            alert('휴대전화번호를 입력해주세요.')
            return
        } else if (form.phone.search(/\s/) > 0 || !/^(010)[0-9]{4}[0-9]{4}$/.test(form.phone)) {
            alert('유효하지 않은 휴대전화번호입니다.')
            return 
        } 

        setIsConnected(true)
        const payload = await requestConsult(form)
        setIsConnected(false)
        
        navigate('/submit')
    }
    
    return (
        <div className={ styles.container }>
            <div className={ styles.fixContainer }>
                <Header />

                { isModalOpen && isModalOpen.isOpen &&      
                    <div className={ styles.modalContainer }>
                        <div className={ styles.modal }>
                            { isModalOpen.type === 'agree' && <AgreeModal type={ isModalOpen.type } buttonExist={ isModalOpen.buttonExist } isAgree={ isModalOpen.isAgree } /> }
                        </div>
                        <div className={ styles.modalBackground }></div>
                    </div>
                } 
            </div>

            <div className={ styles.contents }>
                <img className={ styles.w } src={`${process.env.PUBLIC_URL}/assets/imgs/sub/reception_banner_con_img.png`} alt="banner" />
                <img className={ styles.m } src={`${process.env.PUBLIC_URL}/assets/imgs/sub/m_reception_banner_con_img.png`} alt="banner" />
                
                <div>
                    <ul>
                        <li>
                            <div>
                                <span className={ styles.title }>더스윙제트 창업설명회</span>
                                <span>
                                    MAKE. OUR. FIELD. <br />
                                    더스윙제트 창업설명회 신청 페이지에 <br className={ styles.m } />오신 것을 환영합니다! <br /><br />
                                    * 자체 개발 Z-SYSTEM으로 경험하는 압도적 그래픽 <br />
                                    * 파-티 푸드 (자체 F&B)와 함께 한계 없는 매출 경험 <br />
                                    * 3억으로 가능한 프리미엄 스크린 골프 창업 <br /><br />
                                    스크린 골프 창업의 새로운 솔루션, <br />
                                    더스윙제트 창업설명회에서 예비 창업자님들의 <br className={ styles.m } />성공 솔루션을 상담 받으세요! <br /><br />
                                    ※ 더스윙제트 창업설명회 장소 <br />
                                    경기도 하남시 미사강변중앙로 158번길 27 <br className={ styles.m } />8층 더스윙제트 미사 직영점 <br />
                                    - 지하철 5호선 미사역 9번 출구 200m <br />
                                    - 네비게이션에 '더스윙제트 미사점' 검색(주차 가능)
                                </span>

                                <div className={ styles.titleBox }>
                                    <span>2024.04.12. 오전 09:34 ~ 제한없음</span>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div>
                                <span className={ styles.title }>
                                    <span className={ styles.red }>* </span>
                                    <span className={ styles.orange }>1. </span>
                                    개인정보 수집 동의
                                </span>
                                <span>
                                    동의를 거부하실 수 있으나 동의하신 분에 한하여 <br className={ styles.m } />창업설명회 참석이 가능합니다.
                                </span>

                                <div className={ styles.box }>
                                    <div className={ styles.item }>
                                        <span>수집하는 개인정보 항목</span>
                                        <span>이름, 연락처</span>
                                    </div>
                                    
                                    <div className={ styles.item }>
                                        <span>수집 및 이용 목적</span>
                                        <span>창업 설명회 참석을 위한 창업 컨설턴트 상담 안내</span>
                                    </div>

                                    <div className={ styles.item }>
                                        <span>보유 및 이용기간</span>
                                        <span className={ styles.red }>상담 안내 후 1년간 보관</span>
                                    </div>
                                </div>

                                <div className={ `${ styles.button } ${isAgree && styles.active }` } onClick={ () => setIsAgree(prev => !prev) }>
                                        { isAgree ? 
                                            <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/reception_input_check_icon01_on.svg`} alt="check_on" />
                                                :
                                            <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/reception_input_check_icon01_off.svg`} alt="check_off" />
                                        }

                                    <span>개인정보 수집 및 이용에 동의합니다.</span>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div>
                                <span className={ styles.title }>
                                    <span className={ styles.red }>* </span>
                                    <span className={ styles.orange }>2. </span>
                                    이름을 입력해 주세요.
                                </span>

                                <input type="text" placeholder="이름을 입력해주세요." name="iptUserName" id="iptUserName"
                                    onChange={ (e: ChangeEvent<HTMLInputElement>) => setForm({ ...form, name: e.target.value })}/>
                            </div>
                        </li>

                        <li>
                            <div>
                                <span className={ styles.title }>
                                    <span className={ styles.red }>* </span>
                                    <span className={ styles.orange }>3. </span>
                                    연락처를 입력해 주세요.
                                </span>

                                <input type="text" placeholder="연락처를 입력해주세요." maxLength={ 13 } name="iptUserName" id="iptUserName"
                                    onChange={ (e: ChangeEvent<HTMLInputElement>) => setForm({ ...form, phone: e.target.value })} />
                            </div>
                        </li>

                        <li>
                            <div>
                                <span className={ styles.title }>
                                    <span className={ styles.red }>* </span>
                                    <span className={ styles.orange }>4. </span>
                                    참석가능한 창업설명회 일정을 <br className={ styles.m } />선택해 주세요.
                                </span>

                                <span>창업설명회는 선착순으로 진행되며 선착순 마감 시 <br className={ styles.m } />별도 안내드리도록 하겠습니다.</span>
                                
                                { selectable.map((item: string, index: number ) => {
                                    return (
                                        <div className={ `${ styles.button } ${form.etc === selectable[index] && styles.active }` } onClick={ () => setForm({ ...form, etc: selectable[index] }) }>
                                            <div className={ styles.circle }>
                                                { form.etc === selectable[index] ? 
                                                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/reception_input_check_icon02_on.svg`} alt="check_on" />
                                                        :
                                                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/reception_input_check_icon02_off.svg`} alt="check_off" />
                                                }
                                            </div>
                                            
                                            <span className={ styles.menuText } key={ index }>{ item }</span>
                                        </div>
                                    )
                                })}

                            </div>
                        </li>
                    </ul>
                </div>

                <div className={ styles.submit } onClick={ submit }>
                    <span>제출</span>
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/reception_btn_icon.svg`} alt="submit" />
                </div>
            </div>
            
            <Footer />
        </div>
    )
}

export default Reception