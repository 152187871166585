import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Main from '../src/pages/Main';
import './App.module.scss'
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './redux';
import Submit from './pages/Submit';
import Reception from './pages/Reception';

const store = configureStore({ reducer: rootReducer })

const App = (): JSX.Element => {
    return (
        <div className="App">
            <Provider store={ store }>
                <Routes>
                    <Route path='/' element={ <Main /> } />
                    <Route path='/submit' element={ <Submit /> } />
                    <Route path='/reception' element={ <Reception /> } />
                </Routes>
            </Provider>
        </div>
    )
}

export default App
