import { useNavigate } from "react-router-dom";
import styles from "./Header.module.scss";
import { usePageActions } from "../hooks/usePage";
import { useState } from "react";

const Header = (): JSX.Element => {
    const navigate = useNavigate()
    // const isMenuOpen = useIsMenuOpen()
    
    const [selectedMenu, setSelectedMenu] = useState<number>(0)
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

    const handleNavigation = (scrollNum: number) => {
        if (scrollNum === 7) {
            navigate('/reception') 
            return
        }

        setSelectedMenu(scrollNum)
        navigate(`/?type=menu0${scrollNum}`)
    }

    return (
        <div className={ styles.container }>
            <div className={ styles.header }>
                <img className={ styles.logo } src={`${process.env.PUBLIC_URL}/assets/imgs/main/logo.svg`} alt="더스윙제트" onClick={ () => navigate('/')} />
                <ul className={ styles.navigator }>
                    <li onClick={ () => handleNavigation(1) }>소개</li>
                    <li onClick={ () => handleNavigation(2) }>경쟁력</li>
                    <li onClick={ () => handleNavigation(3) }>창업혜택</li>
                    <li onClick={ () => handleNavigation(4) }>상권분석</li>
                    <li onClick={ () => handleNavigation(5) }>가맹절차</li>
                    <li onClick={ () => handleNavigation(6) }>가맹문의</li>
                    <li onClick={ () => handleNavigation(7) }>창업설명회</li>
                </ul>

                <div className={`${styles.menuButton} ${isMenuOpen ? styles.active : ''}`} onClick={ () => setIsMenuOpen(!isMenuOpen) }>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
                         
            { isMenuOpen && 
                <div>
                    <div className={ styles.menuBackground } onClick={ () => setIsMenuOpen(false) }>
                        <div className={ styles.menuContainer }>
                            <div className={ styles.contents }>
                                <div className={ `${ selectedMenu === 1 && styles.selected }` } onClick={ () => handleNavigation(1) }>
                                    <span>소개</span>
                                    { selectedMenu === 1 && <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/m_main_nav_icon.svg`} alt="menu" /> }
                                </div>

                                <div className={ `${ selectedMenu === 2 && styles.selected }` } onClick={ () => handleNavigation(2) }>
                                    <span>경쟁력</span>
                                    { selectedMenu === 2 && <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/m_main_nav_icon.svg`} alt="menu" /> }
                                </div>

                                <div className={ `${ selectedMenu === 3 && styles.selected }` } onClick={ () => handleNavigation(3) }>
                                    <span>창업혜택</span>
                                    { selectedMenu === 3 && <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/m_main_nav_icon.svg`} alt="menu" /> }
                                </div>

                                <div className={ `${ selectedMenu === 4 && styles.selected }` } onClick={ () => handleNavigation(4) }>
                                    <span>상권분석</span>
                                    { selectedMenu === 4 && <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/m_main_nav_icon.svg`} alt="menu" /> }
                                </div>

                                <div className={ `${ selectedMenu === 5 && styles.selected }` } onClick={ () => handleNavigation(5) }>
                                    <span>가맹절차</span>
                                    { selectedMenu === 5 && <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/m_main_nav_icon.svg`} alt="menu" /> }
                                </div>

                                <div className={ `${ selectedMenu === 6 && styles.selected }` } onClick={ () => handleNavigation(6) }>
                                    <span>가맹문의</span>
                                    { selectedMenu === 6 && <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/m_main_nav_icon.svg`} alt="menu" /> }
                                </div>

                                <div className={ `${ selectedMenu === 1 && styles.selected }` } onClick={ () => handleNavigation(7) }>
                                    <span>창업설명회</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Header
