import { ChangeEvent, useEffect, useState } from "react";
import styles from "./Info10.module.scss";
import { useNavigate } from "react-router";
import { useIsModalOpen, usePageActions } from "../hooks/usePage";
import { Inquiry } from "../types/apiType";
import { useApi } from "../hooks/useApi";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

interface Message {
    type: string,
    msg: string
}

const Info10 = (): JSX.Element => {
    const navigate = useNavigate()
    const { saveIsModalOpen } = usePageActions()
    const { requestConsult } = useApi()
    const isModalOpen = useIsModalOpen()
    
    const [isConnected, setIsConnected] = useState<boolean>(false)
    const [emailType, setEmailType] = useState<string>('')
    const [inquiryInfo, setInquiryInfo] = useState<Inquiry>({
        name: '',
        phone: '',
        store: '',
        area: '',
        type: '',
        etc: '',
        email: '',
        path: '랜딩페이지(기본형)',
        code1: '',
        code2: '',
        code3: '',
        refer: ''
    })
    const [isAgree, setIsAgree] = useState<boolean>(false)
    const [message, setMessage] = useState<Message>({
        type: '',
        msg: ''
    })

    useEffect(() => {
        ScrollTrigger.matchMedia({
            "(min-width: 0px)": function () {
                info10_ani()
            },
        });
    }, [])

    const info10_ani = () => {
        const tl = gsap.timeline()

        tl.from(`.${ styles.titleContainer } > .${ styles.text } > div`,{
            x: -50,
            duration: 0.5,
            ease: "power2.easeOut",
            stagger: 0.03,
            autoAlpha: 0,
        })
        .from(`.${ styles.titleContainer } > .${ styles.background }`, {
            autoAlpha: 0,
            x:-50,
            ease:"back"
        },"-=0.2")     
        .from(`.${ styles.writeContainer }`, {
            autoAlpha: 0,
        },"-=0.2")     
        
        ScrollTrigger.create({
			trigger: `.${ styles.titleContainer }`,
			start: 'top center',
			end: 'bottom center',
			animation: tl,
		//	markers: true,
		})  
    }

    useEffect(() => {   
        setIsAgree(isModalOpen.isAgree)
    }, [isModalOpen])

    const changeEmailType = (event: any) => {
        if (!event.target.value) {
            setEmailType('')
            return
        }
        setEmailType(event.target.value)
    }

    const request = async () => {
        if (isConnected) return

        if (inquiryInfo.name === '') {
            setMessage({ type: 'name', msg: '이름을 입력해주세요.'})
            return
        } else if (inquiryInfo.phone === '') {
            setMessage({ type: 'phone', msg: '휴대전화번호를 입력해주세요.'})
            return
        } else if (inquiryInfo.phone.search(/\s/) > 0 || !/^(010)[0-9]{4}[0-9]{4}$/.test(inquiryInfo.phone)) {
            setMessage({ type: 'phone', msg: '유효하지 않은 휴대전화번호입니다.'})
            return 
        } else if (!isAgree) {
            alert('개인정보수집 및 이용동의를 해주세요.')
            return 
        }

        const params = {
            name: inquiryInfo.name,
            phone: inquiryInfo.phone,
            store: inquiryInfo.store,
            area: inquiryInfo.area,
            type: inquiryInfo.type,
            etc: inquiryInfo.etc,
            email: inquiryInfo.email + emailType,
            path: inquiryInfo.path,
            code1: inquiryInfo.code1,
            code2: inquiryInfo.code2,
            code3: inquiryInfo.code3,
            refer: inquiryInfo.refer
        }

        setIsConnected(true)
        const payload = await requestConsult(params)
        setIsConnected(false)

        navigate('/submit')
        // alert('시스템 점검중입니다. 전화로 문의해주세요.')
    }

    return (
        <div className={ styles.container }>
            <div className={ styles.contents }>
                <div className={ styles.titleContainer }>
                    <div className={ styles.background }>
                        <div className={ styles.left }>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div className={ styles.right }>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div className={ styles.bottom }>
                            <div></div>
                        </div>
                    </div>

                    <div className={ styles.text }>
                        <div>
                            <span>창</span>
                            <span>업</span>
                        </div>

                        <div>
                            <div>
                                <span>상</span>
                            </div>
                            <div>
                                <span>세</span>
                            </div>
                            <div>
                                <span>문</span>
                            </div>
                            <div>
                                <span>의</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={ styles.writeContainer }>
                    <div className={ styles.inputContainer }>
                        <div>
                            <div className={ styles.item }>
                                <div className={ styles.title }>
                                    <span>이름 <span>*</span></span>
                                    { message.type === 'name' && <span className={ styles.message }>{ message.msg }</span> }
                                </div>
                                <div className={ styles.input }>
                                    <input className={ `${message.type === 'name' && styles.errorBorder}` } type="text" placeholder="이름을 입력해주세요." onChange={ (e: ChangeEvent<HTMLInputElement>) => setInquiryInfo({ ...inquiryInfo, name: e.target.value })} />                        
                                </div>
                            </div>

                            <div className={ styles.item }>
                                <div className={ styles.title }>
                                    <span>연락처 <span>*</span></span>
                                    { message.type === 'phone' && <span className={ styles.message }>{ message.msg }</span> }
                                </div>
                                <div className={ styles.input }>
                                    <input className={ `${message.type === 'phone' && styles.errorBorder}` } type="text" placeholder="연락처를 입력해주세요." onChange={ (e: ChangeEvent<HTMLInputElement>) => setInquiryInfo({ ...inquiryInfo, phone: e.target.value })} />                        
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className={ styles.item }>
                                <div className={ styles.title }>
                                    <span>이메일</span>
                                    { message.type === 'email' && <span className={ styles.message }>{ message.msg }</span> }
                                </div>
                                <div className={ styles.input }>
                                    <input type="text" placeholder="이메일" onChange={ (e: ChangeEvent<HTMLInputElement>) => setInquiryInfo({ ...inquiryInfo, email: e.target.value })} />                        
                                    <select name="email" onChange={ changeEmailType }>
                                        <option value="">직접입력</option>
                                        <option value="@naver.com">@naver.com</option>
                                        <option value="@gmail.com">@gmail.com</option>
                                        <option value="@nate.com">@nate.com</option>
                                        <option value="@hanmail.net">@hanmail.net</option>
                                        <option value="@daum.net">@daum.net</option>
                                    </select>
                                </div>
                            </div>

                            <div className={ styles.item }>
                                <div className={ styles.title }>
                                    <span>점포유무</span>
                                    { message.type === 'store' && <span className={ styles.message }>{ message.msg }</span> }
                                </div>
                                <div className={ styles.input }>
                                    <select name="store" onChange={ (e) => setInquiryInfo({ ...inquiryInfo, store: e.target.value })} >
                                        <option value="">점포유무</option>
                                        <option value="유">유</option>
                                        <option value="무">무</option>
                                    </select>   
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className={ styles.item }>
                                <div className={ styles.title }>
                                    <span>창업 희망 지역</span>
                                    { message.type === 'area' && <span className={ styles.message }>{ message.msg }</span> }
                                </div>
                                <div className={ styles.input }>
                                    <select name="area" onChange={ (e) => setInquiryInfo({ ...inquiryInfo, area: e.target.value })} >
                                        <option value="">희망지역</option>
                                        <option value="서울">서울</option>
                                        <option value="경기">경기</option>
                                        <option value="경기">인천</option>
                                        <option value="부산">부산</option>
                                        <option value="대전">대전</option>
                                        <option value="대구">대구</option>
                                        <option value="울산">울산</option>
                                        <option value="세종">세종</option>
                                        <option value="광주">광주</option>
                                        <option value="강원">강원</option>
                                        <option value="충북">충북</option>
                                        <option value="충남">충남</option>
                                        <option value="경북">경북</option>
                                        <option value="경남">경남</option>
                                        <option value="전북">전북</option>
                                        <option value="전남">전남</option>
                                        <option value="제주">제주</option>
                                    </select>   
                                </div>
                            </div>

                            <div className={ styles.item }>
                                <div className={ styles.title }>
                                    <span>창업 희망 형태</span>
                                    { message.type === 'type' && <span className={ styles.message }>{ message.msg }</span> }
                                </div>
                                <div className={ styles.input }>
                                    <select name="type" onChange={ (e) => setInquiryInfo({ ...inquiryInfo, type: e.target.value })} >
                                        <option value="">창업희망형태</option>
                                        <option value="스크린골프 더스윙제트">스크린골프 더스윙제트</option>
                                        <option value="더스윙제트+더스윙블랙 복합점">더스윙제트+더스윙블랙 복합점</option>
                                    </select>   
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className={ styles.item }>
                                <div className={ styles.title }>
                                    <span>유입경로</span>
                                    { message.type === 'path' && <span className={ styles.message }>{ message.msg }</span> }
                                </div>
                                <div className={ styles.input }>
                                    <select name="path" onChange={ (e) => setInquiryInfo({ ...inquiryInfo, path: e.target.value })} >
                                        <option value="">유입경로</option>
                                        <option value="TV광고">TV광고</option>
                                        <option value="온라인광고">온라인광고</option>
                                        <option value="블로그">블로그</option>
                                        <option value="유튜브">유튜브</option>
                                        <option value="창업박람회">창업박람회</option>
                                        <option value="지인소개">지인소개</option>
                                        <option value="전화문의">전화문의</option>
                                        <option value="1월 코엑스 창업박람회">1월 코엑스 창업박람회</option>
                                        <option value="5월대구박람회">5월대구박람회</option>
                                        <option value="4월 세텍 현장 등록">4월 세텍 현장 등록</option>
                                        <option value="3월 코엑스 현장 등록">3월 코엑스 현장 등록</option>
                                        <option value="3월 벡스코 현장 등록">3월 벡스코 현장 등록</option>
                                        <option value="8월 코엑스 현장 등록">8월 코엑스 현장 등록</option>
                                        <option value="기타">기타</option>
                                    </select>   
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className={ styles.item }>
                                <div className={ styles.title }>
                                    <span>문의내용</span>
                                    { message.type === 'etc' && <span className={ styles.message }>{ message.msg }</span> }
                                </div>
                                <div className={ styles.input }>
                                    <textarea name="txtContents" placeholder="문의내용을 입력해주세요." maxLength={ 150 } onChange={ (e) => setInquiryInfo({ ...inquiryInfo, etc: e.target.value })}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={ styles.agreeContainer }>
                        <div onClick={ () => setIsAgree(prev => !prev) }>
                            { isAgree ? 
                                <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/checkbox_icon_on.svg`} alt="info08_check_on" />
                                    :
                                <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/checkbox_icon_off.svg`} alt="info08_check_off" />
                            }
                            <span>개인정보수집 및 이용동의</span>
                        </div>
                        
                        <span onClick={ () => saveIsModalOpen({ type: 'agree', buttonExist: true, isOpen: true, isAgree: isAgree }) }>자세히보기</span>
                    </div>

                    <div className={ styles.button } onClick={ request }>
                        <div>
                            <span>창업문의</span>
                            <img className={ styles.w } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info10_button_w.svg`} alt="info10_button_w" />
                            <img className={ styles.m } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info10_button_m.svg`} alt="info10_button_m" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Info10