import { ChangeEvent, useEffect, useState } from "react";
import { useIsModalOpen, usePageActions } from "../hooks/usePage";
import styles from "./Footer.module.scss";
import { Inquiry } from "../types/apiType";
import { useApi } from "../hooks/useApi";

const Footer = (): JSX.Element => {
    const { saveIsModalOpen } = usePageActions()
    const { requestConsult } = useApi()
    const isModalOpen = useIsModalOpen()

    const [isConnected, setIsConnected] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isAgree, setIsAgree] = useState<boolean>(false)
    const [bottomForm, setBottomForm] = useState<Inquiry>({
        name: '',
        phone: '',
        store: '',
        area: '',
        type: '',
        etc: '',
        email: '',
        path: '랜딩페이지(기본형)',
        code1: '',
        code2: '',
        code3: '',
        refer: ''
    })

    useEffect(() => {   
        setIsAgree(isModalOpen.isAgree)
    }, [isModalOpen])

    const call = () => {
        window.location.href = 'tel:18559753'
    }
 
    const request = async () => {
        if (isConnected) return 

        if (bottomForm.name === '') {
            alert('이름을 입력해주세요.')
            return
        } else if (bottomForm.phone === '') {
            alert('휴대전화번호를 입력해주세요.')
            return
        } else if (bottomForm.phone.search(/\s/) > 0 || !/^(010)[0-9]{4}[0-9]{4}$/.test(bottomForm.phone)) {
            alert('유효하지 않은 휴대전화번호입니다.')
            return 
        } else if (!isAgree) {
            alert('개인정보수집 및 이용동의를 해주세요.')
            return 
        }

        setIsConnected(true)
        const payload = await requestConsult(bottomForm)
        setIsConnected(false)
        
        saveIsModalOpen({ type: 'complete', buttonExist: true, isAgree: isModalOpen.isAgree, isOpen: true })
    }

    return (
        <div className={ styles.container }>
            <div className={ styles.contents }>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/footer_logo.svg`} alt="footer_logo" />

                    <div>
                        <span>주식회사 더스윙골프</span>
                    </div>
                    <div>
                        <span>사업자등록번호 : 403-87-01586  ㅣ  대표자 : 최재호  <span>ㅣ</span> 
                        <br className={ styles.m } />서울 강남구 논현로134길 10, 6층</span>
                        <br className={ styles.m } /><span>대표번호 : 1855-0753</span>
                    </div>
                    <div>
                        <span>COPYRIGHT© THE SWING GOLF CO. <br className={ styles.m } /> ALL RIGHTS RESERVED.</span>
                        <span></span>
                    </div>
                </div>

                <span onClick={ () => saveIsModalOpen({ type: 'agree', buttonExist: false, isOpen: true, isAgree: true })}>개인정보처리방침</span>
            </div>

            <div className={`${ styles.bottomQuick } ${ isOpen && styles.on }` }>  
                <div className={ styles.contents }>
                    <div className={ styles.button } onClick={ () => setIsOpen(prev => !prev) }>
                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/bottom_quick_btn_con_arrow.svg`} alt="bottom_arrows" />
                        <span>펼치기</span>
                    </div>

                    <div className={ styles.inputContainer }>
                        <div>
                            <div className={ styles.tel }>
                                <span>상담문의</span>

                                <div>
                                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/bottom_quick_tel_icon_bk.svg`} alt="bottom_tel" />
                                    <span onClick={ call }>1855-0753</span>
                                </div>
                            </div>

                            <ul>
                                <li>
                                    <input type="text" placeholder="이름(필수)" maxLength={ 5 } onChange={ (e: ChangeEvent<HTMLInputElement>) => setBottomForm({ ...bottomForm, name: e.target.value })}  />
                                </li>
                                <li>
                                    <input type="text" placeholder="연락처(필수)" maxLength={ 13 } onChange={ (e: ChangeEvent<HTMLInputElement>) => setBottomForm({ ...bottomForm, phone: e.target.value })} />
                                </li>
                            </ul>

                            <div className={ styles.agreeContainer }>
                                <div onClick={ () => setIsAgree(prev => !prev) }>
                                    { isAgree ? 
                                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/checkbox_icon_on.svg`} alt="info08_check_on" />
                                            :
                                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/checkbox_icon_off.svg`} alt="info08_check_off" />
                                    }
                                    <span>개인정보수집 및 이용동의</span>
                                </div>
                                    
                                <span onClick={ () => saveIsModalOpen({ type: 'agree', buttonExist: true, isOpen: true, isAgree: isAgree }) }>자세히보기</span>
                            </div>

                            <div className={ styles.submit } onClick={ request }>
                                <span>상담신청</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>     
        </div>
    )
}

export default Footer
