import { usePageActions } from "../hooks/usePage";
import styles from "./AgreeModal.module.scss";

interface Props {
    type: string,
    buttonExist: boolean,
    isAgree: boolean
}

const AgreeModal = (props: Props) => {
    const type = props.type
    const isAgree = props.isAgree ?? false
    const buttonExist = props.buttonExist ?? true

    const { saveIsModalOpen } = usePageActions()

    return (
        <div className={ styles.container }>
            <div className={ styles.contents }>
                <div className={ styles.titleContainer }>
                    <span>개인정보수집 및 이용동의</span>
                    <div onClick={ () => saveIsModalOpen({ type: '', buttonExist: buttonExist, isOpen: false, isAgree: isAgree })}>
                        <img className={ styles.w } src={`${process.env.PUBLIC_URL}/assets/imgs/sub/close_w.svg`} alt="close_w" />
                        <img className={ styles.m } src={`${process.env.PUBLIC_URL}/assets/imgs/sub/close_m.svg`} alt="close_m" />
                    </div>
                </div>

                <div className={ styles.contentsContainer }>
                    <div className={ styles.text }>
                        <span>
                            더스윙제트는 다음과 같이 개인정보를 수집 및 이용하며, 수집한 개인정보는 가맹 삼담 및 서비스를 위해 활용합니다. <br />
                            ■ 수집하는 개인정보 항목 <br />
                            ◇ 필수 : 이름, 연락처, 이메일, 점포유무, 쿠키 <br />
                            ◇ 선택 : 희망지역, 희망형태, 유입경로, 문의내용 <br />
                            ■ 개인정보 수집 목적 <br />
                            - 원활한 창업 및 가맹 상담 <br />
                            - 서비스 이용 및 가맹 정보 등의 이메일, SMS 전송 및 유선 연락 <br />
                            ■ 개인정보 보유 및 이용 기간 <br />
                            - 접수 기간 및 상담 완료 후 1년 <br />
                            고객은 본 개인정보 수집 및 이용 동의를 거부할 수 있으며, 동의 거부 시 가맹 문의 및 상담 등 <br />
                            서비스 이용에 제한이 있을 수 있습니다.
                        </span>
                    </div>
                </div>
                
                { buttonExist &&
                    <div className={ styles.button } onClick={ () => saveIsModalOpen({ type: type, buttonExist: buttonExist, isOpen: false, isAgree: !isAgree })}>
                        { isAgree ? <span>취소합니다</span> : <span>동의합니다</span>}
                    </div>
                }
                
            </div>
        </div>
    )
}

export default AgreeModal