import { useNavigate } from "react-router";
import Footer from "../components/Footer";
import Header from "../components/Header";
import styles from "./Submit.module.scss";
import { useIsModalOpen } from "../hooks/usePage";
import AgreeModal from "../components/AgreeModal";
import { useEffect } from "react";

const Submit = () => {
    const navigate = useNavigate()
    const isModalOpen = useIsModalOpen()
    
    useEffect(() => {
        window.scroll({ top: 0, behavior: 'smooth' })
    }, [])
    
    return (
        <div className={ styles.container }>
            <div className={ styles.fixContainer }>
                <Header />

                { isModalOpen && isModalOpen.isOpen &&      
                    <div className={ styles.modalContainer }>
                        <div className={ styles.modal }>
                            { isModalOpen.type === 'agree' && <AgreeModal type={ isModalOpen.type } buttonExist={ isModalOpen.buttonExist } isAgree={ isModalOpen.isAgree } /> }
                            
                        </div>
                        <div className={ styles.modalBackground }></div>
                    </div>
                } 
            </div>

            <div className={ styles.contents }>
                <span>
                    접수가 완료되었습니다. <br />
                    담당자가 빠른 시간 안에 전화로 연락드리겠습니다.
                </span>

                <div className={ styles.button }>
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/submit_arrow_1.svg`} alt="arrow_1" />
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/sub/submit_arrow_2.svg`} alt="arrow_2" />

                    <span onClick={ () => navigate('/') }>메인으로</span>
                </div>
            </div>
            
            <Footer />
        </div>
    )
}

export default Submit