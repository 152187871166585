import { usePageActions } from "../hooks/usePage";
import styles from "./CompleteModal.module.scss";

interface Props {
    type: string,
    buttonExist: boolean,
    isAgree: boolean
}

const CompleteModal = (props: Props) => {
    const type = props.type
    const isAgree = props.isAgree ?? false
    const buttonExist = props.buttonExist ?? true

    const { saveIsModalOpen } = usePageActions()

    return (
        <div className={ styles.container }>
            <div className={ styles.contents }>
                <div className={ styles.titleContainer }>
                    <span className={ styles.title }>신청 완료</span>

                    <div onClick={ () => saveIsModalOpen({ type: '', buttonExist: buttonExist, isOpen: false, isAgree: isAgree })}>
                        <img className={ styles.w } src={`${process.env.PUBLIC_URL}/assets/imgs/sub/close_w.svg`} alt="close_w" />
                        <img className={ styles.m } src={`${process.env.PUBLIC_URL}/assets/imgs/sub/close_m.svg`} alt="close_m" />
                    </div>
                </div>

                <div className={ styles.contentsContainer }>
                    <span>
                        상담이 신청되었습니다. <br />
                        감사합니다.
                    </span>
                </div>
                
                <div className={ styles.button } onClick={ () => saveIsModalOpen({ type: type, buttonExist: buttonExist, isOpen: false, isAgree: isAgree })}>
                    <span>확인</span>
                </div>           
            </div>
        </div>
    )
}

export default CompleteModal