import React, { useEffect, useState } from "react"
import styles from './Info06.module.scss'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Info06 = (): JSX.Element => {
    const [count1, setCount1] = useState<number>(0)
    const [count2, setCount2] = useState<number>(0)
    //info06 카운트업
    // const info06_counterUp = () => {
    //     // $(".info06_con .count_text.count01").html("37,200,000");
    //     // $(".info06_con .count_text.count02").html("14,850,000");
        
    //     // $('.info06_con .count_text').counterUp({
    //     //     time: 500
    //     // });
    // }

    // info06 애니메이션
    const info06_ani = () => {

        const tl = gsap.timeline();

        tl.from(`.${styles.info06_con} > .${styles.contents_con} > .${styles.title_con} > .${styles.text01_con}`,{
            autoAlpha:0,
            y:50,
        })
        .from(`.${styles.info06_con} > .${styles.contents_con} > .${styles.title_con} > .${styles.text01_con} .${styles.color_text} > .${styles.dot}`,{
            autoAlpha:0,
            scale:3,
            duration:0.15,
            stagger:0.15,
            ease: "back",
        },"-=0.4")
        .from(`.${styles.info06_con} > .${styles.contents_con} > .${styles.title_con} > .${styles.text02_con}`,{
            autoAlpha:0,
            y:50,
            duration:0.4,
        },"-=0.4")
        .from(`.${styles.info06_con} > .${styles.contents_con} > .${styles.contents_con}`,{
            autoAlpha:0,
            duration:0.4,
        })
        .from(`.${styles.info06_con} > .${styles.contents_con} > .${styles.contents_con} > .${styles.inner_con} > .${styles.text_con}`,{
            autoAlpha:0,
            x:50,
            ease:"back"
        },"-=0.2");
        // .call(info06_counterUp, null, null, "<") // 카운트업 애니메이션 호출

        ScrollTrigger.create({
            trigger: `.${styles.info06_con} > .${styles.contents_con} > .${styles.title_con}`,
            start: 'top center',
            end: 'bottom center',
            animation: tl,

        //	markers: true,
        });  
    }

    useEffect(() => {
        info06_ani();
    }, []);

    return (
        <div className={styles.info06_con}>
            <div className={styles.contents_con}>
                <div className={styles.title_con}>
                    <div className={styles.text01_con}>
                        <span>
                            더스윙제트의 <span className={styles.color_text}>한<div className={styles.dot}></div>
                            </span>
                            <span className={styles.color_text}>계<div className={styles.dot}></div>
                            </span>
                            <span className={styles.color_text}>없<div className={styles.dot}></div>
                            </span>
                            <span className={styles.color_text}>는<div className={styles.dot}></div>
                            </span>
                            <span className={styles.color_text}>매<div className={styles.dot}></div>
                            </span>
                            <span className={styles.color_text}>출<div className={styles.dot}></div>
                            </span>로 경험하는
                        </span>
                    </div>

                    <div className={styles.text02_con}>
                        <span>
                            놀라운 기대 수익 제안
                        </span>
                    </div>
                </div>

                <div className={styles.contents_con}>
                    <div className={styles.inner_con}>
                        <div className={styles.graph_con}>
                            <div className={styles.img_con}>
                                <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info06_con_graph_img.svg`} alt="컨텐츠 이미지" className={styles.fx}/>
                            </div>

                            <div className={`${styles.text_con} ${styles.text01_con}`}>
                                <span>
                                    F&B <br/>
                                    7,200,000
                                </span>
                            </div>

                            <div className={`${styles.text_con} ${styles.text02_con}`}>
                                <span>
                                    게임 매출 <br/>
                                    30,000,000
                                </span>
                            </div>
                        </div>

                        <div className={styles.text_con}>
                            <div className={styles.text01}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className={styles.title_td} align="left">
                                                <span>
                                                    게임 매출
                                                </span>
                                            </td>
                                            <td className={styles.text_td} align="right">
                                                <span>
                                                    30,000,000원
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={styles.title_td} align="left">
                                                <span>
                                                    F&B 매출
                                                </span>
                                            </td>
                                            <td className={styles.text_td} align="right">
                                                <span>
                                                    7,200,000원
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className={styles.text02}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className={styles.title_td} align="right">
                                                <span>
                                                    월 예상 매출
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={styles.text_td} align="right">
                                                <span>
                                                    <span className={`${styles.count_text} ${styles.count01}`}>37,200,000</span>
                                                    <span className={styles.color_text}>원</span>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className={styles.text03}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className={styles.title_td} align="right">
                                                <span>
                                                    월 예상 수익
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={styles.text_td} align="right">
                                                <span>
                                                    <span className={`${styles.count_text} ${styles.count02}`}>14,850,000</span>
                                                    <span className={styles.color_text}>원</span>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className={styles.etc_con}>
                                <span>
                                    *5개 룸 운영, 룸 별 8게임 / 일 기준
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Info06;