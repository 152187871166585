
import { useEffect, useState } from "react";
import styles from "./Info02.module.scss";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

const Info02 = (): JSX.Element => {
    const [count, setCount] = useState<number>(0)
    let randomNum = Math.floor(Math.random() * 11)

    useEffect(() => {
        ScrollTrigger.matchMedia({
            "(min-width: 0px)": function () {
                info02_ani();
            },
        });
    }, [])

    const info02_ani = () => {

        const tl = gsap.timeline();

        // 애니메이션 타임라인 정의
        tl.from(`.${ styles.titleContainer } > .${ styles.svgContainer } > div > span`, {
            xPercent: -50,
            duration: 0.5,
            ease: "back(1.3)",
            autoAlpha: 0,
        })
        .from(`.${ styles.titleContainer } > .${ styles.title } > span`, {
            y: 30,
            duration: 0.4,
            ease: "sine",
            autoAlpha: 0,
        }, "-=0.1")
        .from(`.${ styles.titleContainer } > span`, {
            y: 30,
            duration: 0.4,
            ease: "sine",
            autoAlpha: 0,
        }, "<")
        ScrollTrigger.create({
            trigger: `.${ styles.titleContainer } `,
            start: "top 70%",
            end: "bottom 70%",
            animation: tl,
            onEnter: () => {
                const interval = setInterval(() => {
                    setCount((prevCount) => {
                        if (prevCount < 245 + randomNum) {
                            return prevCount + 1
                        } else {
                            clearInterval(interval)
                            return prevCount
                        }
                    })
                }, 5)
            }
            // markers: true, // 디버깅용
        });
    }

    const getDate = () => {
        const currentTime = new Date()

        const year = currentTime.getFullYear().toString().slice(2, 4)
        const date = currentTime.getDate()

        return `${year}년 ${currentTime.getMonth() + 1}월 ${Math.ceil(date / 7)}주차`
    }

    return (
        <div className={ styles.container }>
            <div className={ styles.titleContainer }>
                <div className={ styles.svgContainer }>
                    <img className={ styles.img_w } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info02_w.svg`} alt="info02" />
                    <img className={ styles.img_m } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info02_m.svg`} alt="info02" />
                    <div>
                        <span>스크린 골프 창업의 새로운 기준</span>
                    </div>
                </div>

                <div className={ styles.title }>
                    <span>
                        <span>더스윙제트</span>와 <br />함께 시작하세요!
                    </span>
                </div>

                <span>최신타석을, 최적의 상권에, <br />가장 합리적인 금액으로 창업하세요.</span>
            </div>

            <div className={ styles.contents }>
                <div className={ styles.title }>
                    <span>금주의 상담 현황</span>
                </div>

                <div className={ styles.date }>
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info02_contents_text_text01_icon.svg`} alt="info02_cal" />
                    <span>{ getDate() }</span>
                </div>

                <div className={ styles.consult }>
                    <span>
                        <span>{ count }건</span> 상담 중
                    </span>
                </div>

                <span>상담은 빠를 수록 더 좋은 상권 확보가 가능합니다.</span>
            </div>
        </div>
    )
}

export default Info02
