
import styles from "./Main.module.scss";

import { useIsModalOpen } from '../hooks/usePage';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Info01 from './Info01';
import Info02 from './Info02';
import Info03 from './Info03';
import Info04 from './Info04';
import Info08 from './Info08';
import Info09 from './Info09';
import Info10 from './Info10';
import Header from '../components/Header';
import QuickBar from '../components/QuickBar';
import Footer from '../components/Footer';
import AgreeModal from "../components/AgreeModal";
import Info12 from "./Info12";
import Info06 from "./Info06";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CompleteModal from "../components/CompleteModal";

gsap.registerPlugin(ScrollTrigger)

const Main = (): JSX.Element => {
    const navigate = useNavigate()
    const location = useLocation()
    const isModalOpen = useIsModalOpen()
    
    const contentRef2 = useRef<HTMLDivElement>(null)
    const contentRef3 = useRef<HTMLDivElement>(null)
    const contentRef8 = useRef<HTMLDivElement>(null)
    const contentRef9 = useRef<HTMLDivElement>(null)
    const contentRef10 = useRef<HTMLDivElement>(null)
    const contentRef12 = useRef<HTMLDivElement>(null)

    const queryParams = new URLSearchParams(location.search)
    const type = queryParams.get('type')

    useEffect(() => {
        // ScrollTrigger와 애니메이션 정리 (cleanup)
        return () => {
            ScrollTrigger.killAll();
            gsap.timeline().kill();
        }
    }, []);


    useLayoutEffect(() => {
        const handleScroll = () => {
            switch (type) {
                case 'menu01':
                    scroll(contentRef2)
                    return
                case 'menu02':
                    scroll(contentRef3)
                    return
                case 'menu03':
                    scroll(contentRef12)
                    return
                case 'menu04':
                    scroll(contentRef8)
                    return
                case 'menu05':
                    scroll(contentRef9)
                    return
                case 'menu06':
                    scroll(contentRef10)
                    return
            }
        }

        handleScroll()

        setTimeout(() => {
            handleScroll()
          }, 500)
    }, [type])

    const scroll = (ref: React.RefObject<HTMLDivElement>) => {
        const isMobile = window.innerWidth <= 1024 ? true : false
        if (ref.current) {
            const offsetTop = ref.current.getBoundingClientRect().top + window.scrollY - (isMobile ? 10 : 195); // 210px 위로 이동
            window.scrollTo({ top: offsetTop, behavior: 'smooth' });
        }
    }

    return (
        <div className={ styles.container }>
            <div className={ styles.fixContainer }>
                <Header />
                <QuickBar />
                { isModalOpen && isModalOpen.isOpen &&      
                    <div className={ styles.modalContainer }>
                        <div className={ styles.modal }>
                            { isModalOpen.type === 'agree' && <AgreeModal type={ isModalOpen.type } buttonExist={ isModalOpen.buttonExist } isAgree={ isModalOpen.isAgree } /> }
                            { isModalOpen.type === 'complete' && <CompleteModal type={ isModalOpen.type } buttonExist={ isModalOpen.buttonExist } isAgree={ isModalOpen.isAgree } /> }
                            
                        </div>
                        <div className={ styles.modalBackground }></div>
                    </div>
                }   
            </div>
            <div className={ styles.routeContainer  }>
                <div className={ styles.contents }>
                    <Info01 />
                    <div ref={ contentRef2 }>
                        <Info02 />
                    </div>
                    <div ref={ contentRef3 }>
                        <Info03 />
                    </div>
                    <div ref={ contentRef12 }>
                        <Info12 />
                        <Info06 />
                    </div>
                    <div ref={ contentRef8 }>
                        <Info04 />
                        <Info08 />
                    </div>
                    <div ref={ contentRef9 }>
                        <Info09 />
                    </div>
                   
                    <div ref={ contentRef10 }>
                        <Info10 />
                    </div>
                </div>

                <Footer />
            </div>

            <div className={ styles.rightModal } onClick={ () => navigate('/reception')}>
                <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/right_quick_img.png`} alt="right_img" />

                <div>
                    <span>창업설명회 <br className={ styles.m } />신청</span>
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/right_quick.svg`} alt="right" />
                </div>
            </div>

            <div className={ styles.goTopBtn } onClick={ () => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/bottom_top_icon.svg`} alt="goTop" />
            </div>
        </div>
    )
}

export default Main
