import { useEffect } from "react";
import styles from "./Info04.module.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Info04 = (): JSX.Element => {
    useEffect(() => {
        ScrollTrigger.matchMedia({
            "(min-width: 0px)": function () {
                info04_ani()
            },
        });
    }, [])

    const info04_ani = () => {
        const tl = gsap.timeline()
        const tlTitle = gsap.timeline()
        const tlGraph = gsap.timeline()

        // 애니메이션 타임라인 정의
        tl.from(`.${ styles.container } > .${ styles.titleContainer } > .${ styles.contents } > .${ styles.subContainer }`, {
            xPercent: -50,
            duration: 0.5,
            ease: "power2.easeOut",
            stagger: 0.03,
            autoAlpha: 0,
        })
        tl.from(`.${ styles.container } > .${ styles.titleContainer } > .${ styles.contents } > .${ styles.boldContainer } span`, {
            marginLeft: 0,
            ease: "sine",
            autoAlpha: 0,
        }, "-=0.4")
        tl.from(`.${ styles.container } > .${ styles.titleContainer } > .${ styles.contents } > .${ styles.dotContainer }`, {
            y: 20,
            duration: 0.4,
            ease: "sine",
            autoAlpha: 0,
        }, "-=0.1")
        tl.from(`.${ styles.container } > .${ styles.titleContainer } > .${ styles.contents } > .${ styles.dotContainer } > span > span`, {
            scale: 3,
            duration: 0.15,
            ease: "back",
            stagger: 0.15,
            autoAlpha: 0,
        }, "-=0.2")
        ScrollTrigger.create({
            trigger: `.${ styles.container }`,
            animation: tl,
            start: "top 70%",
            end: "bottom 70%",
        })

        // 타이틀
        tlTitle.from(`.${ styles.contentsContainer } > .${ styles.contents } > .${ styles.titleContainer }`, {
            xPercent: -30,
            duration: 0.6,
            ease: "back(1.3)",
            autoAlpha: 0,
        })
        ScrollTrigger.create({
            trigger: `.${ styles.contentsContainer }`,
            animation: tlTitle,
            start: "top 60%",
            end: "bottom 60%",
        })

        // 그래프
        tlGraph.from(`.${ styles.graphContainer }`, {
            autoAlpha: 0,
        })
        tlGraph.from(`.${ styles.graphContainer } > .${ styles.imgContainer } > img`, {
            scale: 0,
            duration: 0.6,
        }, "-=0.2")
        ScrollTrigger.create({
            trigger: `.${ styles.graphContainer }`,
            animation: tlGraph,
            start: "top 65%",
            end: "bottom 65%",
        })
    }

    return (
        <div className={ styles.container }>
            <div className={ styles.titleContainer }>
                <div className={ styles.contents }>
                    <div className={ styles.subContainer }>
                        <span>스크린 골프 성공의 핵심은</span>
                    </div>

                    <div className={ styles.boldContainer }>
                        <div className={ styles.background }>
                            <img className={ styles.w } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info04_title_w.svg`} alt="info04_w" />
                            <img className={ styles.m } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info04_title_m.svg`} alt="info04_m" />
                        </div>
                    
                        <span>
                            바로 <span className={ styles.color }>입지</span>
                        </span>
                    </div>

                    <div className={ styles.dotContainer }>
                        <span>
                            대한민국 어디든, 상권보호 제약 없이 
                            <span>&nbsp;지<div className={ styles.dot }></div></span>
                            <span>&nbsp;금<div className={ styles.dot }></div></span>
                            ,<br className={ styles.m } /> 원하는 상권에 
                            <span>&nbsp;바<div className={ styles.dot }></div></span>
                            <span>&nbsp;로<div className={ styles.dot }></div></span>
                            <span>&nbsp;창<div className={ styles.dot }></div></span>
                            <span>&nbsp;업<div className={ styles.dot }></div></span>
                            이 가능한 더스윙제트
                        </span>
                    </div>
                </div>
            </div>

            <div className={ styles.contentsContainer }>
                <div className={ styles.contents }>
                    <div className={ styles.titleContainer }>
                        <div>
                            <span>더스윙골프가 제공하는</span>
                        </div>
                        <div>
                            <span>상권 분석 시스템</span>
                        </div>
                        <div className={ styles.background }>
                            <img className={ styles.w } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info04_contents_bg_w.svg`} alt="info04_w" />
                            <img className={ styles.m } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info04_contents_bg_m.svg`} alt="info04_m" />
                        </div>
                    </div>

                    <div className={ styles.graphContainer }>
                        <div className={ styles.imgContainer }>
                            <div className={ styles.backround }>
                                <img className={ styles.w } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info04_polygon_bg_w.png`} alt="info04_w" />
                                <img className={ styles.m } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info04_polygon_bg_m.png`} alt="info04_m" />
                            </div>

                            <img className={ styles.w } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info04_polygon_w.png`} alt="info04_w" />
                            <img className={ styles.m } src={`${process.env.PUBLIC_URL}/assets/imgs/main/info04_polygon_m.png`} alt="info04_m" />
                        </div>

                        <div className={ styles.listContainer }>
                            <ul>
                                <li>
                                    <div className={ styles.title }>
                                        <span>접근성</span>
                                    </div>
                                    <div className={ styles.sub }>
                                        <span>버스 정류장, 역 등 위치 기반</span>
                                    </div>
                                    
                                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info04_graph_dot.svg`} alt="info04_dot" />
                                </li>
                                <li>
                                    <div className={ styles.title }>
                                        <span>유동 인구</span>
                                    </div>
                                    <div className={ styles.sub }>
                                        <span>지역 내 인구 밀도 유추</span>
                                    </div>
                                    
                                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info04_graph_dot.svg`} alt="info04_dot" />
                                </li>
                                <li>
                                    <div className={ styles.title }>
                                        <span>직장 <br className={ styles.m } />인구</span>
                                    </div>
                                    <div className={ styles.sub }>
                                        <span>오피스 밀집 지역</span>
                                    </div>

                                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info04_graph_dot.svg`} alt="info04_dot" />
                                </li>
                                <li>
                                    <div className={ styles.title }>
                                        <span>경쟁사 <br className={ styles.m } />분포</span>
                                    </div>
                                    <div className={ styles.sub }>
                                        <span>해당 상권 스크린골프 매장 분포</span>
                                    </div>

                                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info04_graph_dot.svg`} alt="info04_dot" />
                                </li>
                                <li>
                                <div className={ styles.title }>
                                        <span>주거 인구</span>
                                    </div>
                                    <div className={ styles.sub }>
                                        <span>단지 밀집 지역 및 베드 타운</span>
                                    </div>

                                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info04_graph_dot.svg`} alt="info04_dot" />
                                </li>
                                <li>
                                    <div className={ styles.title }>
                                        <span>매출</span>
                                    </div>
                                    <div className={ styles.sub }>
                                        <span>해당 상권 프랜차이즈 월평균 매출</span>
                                    </div>

                                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info04_graph_dot.svg`} alt="info04_dot" />
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className={ styles.subContainer }>
                        <span>
                            <span>6개 핵심 지표 기준</span> 예비 점주님 <br className={ styles.m } />성향에 맞춰 Scoring 분석 제안
                        </span>
                    </div>

                    <div className={ styles.mobileList }>
                        <div>
                            <span className={ styles.title }>접근성</span>
                            <span className={ styles.sub }>버스 정류장, 역 등 위치 기반</span>
                        </div>
                        <div>
                            <span className={ styles.title }>유동 인구</span>
                            <span className={ styles.sub }>지역 내 인구 밀도 유추</span>
                        </div>
                        <div>
                            <span className={ styles.title }>직장 인구</span>
                            <span className={ styles.sub }>오피스 밀집 지역</span>
                        </div>
                        <div>
                            <span className={ styles.title }>경쟁사 분포</span>
                            <span className={ styles.sub }>해당 상권 스크린골프 매장 분포</span>
                        </div>
                        <div>
                            <span className={ styles.title }>주거 인구</span>
                            <span className={ styles.sub }>단지 밀집 지역 및 베드 타운</span>
                        </div>
                        <div>
                            <span className={ styles.title }>매출</span>
                            <span className={ styles.sub }>해당 상권 프랜차이즈 월평균 매출</span>
                        </div>
                    </div>
                </div>
         
            </div>
        </div>
    )
}

export default Info04