import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface Modal {
    type: string,
    buttonExist: boolean,
    isOpen: boolean,
    isAgree: boolean,
}

interface PageState {
    isModalOpen: Modal,
}

const initialState: PageState = {
    isModalOpen: {
        type: '',
        buttonExist: true,
        isOpen: false,
        isAgree: false,
    },
}

const pageSlice = createSlice ({
    name: 'page',
    initialState,
    reducers: {
        saveIsModalOpen(state, action: PayloadAction<Modal>) {
            state.isModalOpen = action.payload
        }
    }
})

export default pageSlice.reducer
export const { saveIsModalOpen } = pageSlice.actions