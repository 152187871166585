
import { useRef } from 'react';
import styles from "./Info01.module.scss";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade, Autoplay } from 'swiper/modules';
import "swiper/css";
import "swiper/css/effect-fade";

const Info01 = (): JSX.Element => {
    const swiperRef = useRef<any>(null)
    const mSwiperRef = useRef<any>(null)
    
    const goPrevSlide = () => {
        swiperRef.current.swiper.slidePrev()
        mSwiperRef.current.swiper.slidePrev()
    }

    const goNextSlide = () => {
        swiperRef.current.swiper.slideNext()
        mSwiperRef.current.swiper.slideNext()
    }

    return (
        <div className={ styles.container }>
            <Swiper
                ref={ swiperRef }
                slidesPerView={ 1 }
                className={ styles.swiper }
                modules={[Navigation, EffectFade, Autoplay]}
                effect="fade"
                navigation={ true } 
                fadeEffect={{ crossFade: true }} 
                loop={ true } 
                speed={ 1500 } 
                autoplay={{ delay: 5000, disableOnInteraction: false }}
            >
                <SwiperSlide>
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info01_con_slide_con_img01.png`} alt="info01_1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info01_con_slide_con_img02.png`} alt="info01_2" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info01_con_slide_con_img03.png`} alt="info01_3" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info01_con_slide_con_img04.jpg`} alt="info01_4" />
                </SwiperSlide>
            </Swiper>

            <div className={ styles.prev } onClick={ goPrevSlide }>
                <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info01_con_slide_prev_btn.svg`} alt="info01_prev" />
            </div>
            <div className={ styles.next } onClick={ goNextSlide }>
                <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info01_con_slide_next_btn.svg`} alt="info01_next" />
            </div>

            {/* mobile */}
            <Swiper
                ref={ mSwiperRef }
                slidesPerView={ 1 }
                className={ styles.swiperMobile }
                modules={[Navigation, EffectFade, Autoplay]}
                effect="fade"
                navigation={ true } 
                fadeEffect={{ crossFade: true }} 
                loop={ true } 
                speed={ 1500 } 
                autoplay={{ delay: 5000, disableOnInteraction: false }}
            >
                <SwiperSlide>
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info01_con_slide_con_img01_m.png`} alt="info01_1" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info01_con_slide_con_img02_m.png`} alt="info01_2" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info01_con_slide_con_img03_m.png`} alt="info01_3" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={`${process.env.PUBLIC_URL}/assets/imgs/main/info01_con_slide_con_img04_m.jpg`} alt="info01_4" />
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default Info01
